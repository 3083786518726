<template>
  <div class="productModal">
    <div class="productModal-inner">
      <div class="productModal-close" @click="closeProductInfo()">&times;</div>
      <h3>
        {{ modalInfo.nombre
        }}<img
          src="../../../assets/expandir.png"
          alt=""
          id="goToCourse-icon"
          @click="goToCourse()"
        />
      </h3>
      <div class="embed-responsive embed-responsive-21by9">
        <video src="../../../assets/CARNET1.mp4" controls></video>
      </div>
      <p>
        {{ modalInfo.descripcion }}
      </p>
      <div class="productModal-extra">
        <span class="text-left text-secondary"
          ><img
            src="../../../assets/minicertificate.svg"
            alt=""
            style="width: 30px"
          />Incluye certificado</span
        >
        <span class="text-left text-secondary"
          ><img
            src="../../../assets/minicertificate.svg"
            alt=""
            style="width: 30px"
          />100% virtual</span
        >
      </div>
      <button
        type="button"
        class="btn btn-warning"
        id="productModal-btn"
        @click="openPaymentModal()"
      >
        Comprar ahora
      </button>
    </div>
  </div>
</template>
<script>
import { useProductInfo } from "../../../store/productModal";
import { usePaymentModal } from "../../../store/paymentModal";

export default {
  name: "ProductModal",
  data() {
    return {};
  },
  computed: {
    modalInfo() {
      return useProductInfo().$state.productModalInfo;
    },
  },
  methods: {
    closeProductInfo() {
      this.$emit("closeProductInfo");
    },
    openPaymentModal() {
      const newProduct = useProductInfo().$state.productModalInfo;
      usePaymentModal().updateModalInfo(newProduct);
      this.$emit("openPaymentModal");
    },
    goToCourse() {
      localStorage.setItem("mode", this.mode);
      var type =
        this.getProductType(this.modalInfo.tipo_producto_id) == null
          ? "oferta"
          : this.getProductType(this.modalInfo.tipo_producto_id);
      if (type == "oferta") {
        const config = {
          slug: this.modalInfo.slug,
        };
        this.$router.push({
          name: "oferta",
          params: config,
        });
      } else {
        const config = {
          type: type,
          slug: this.modalInfo.slug,
        };
        this.$router.push({
          name: "producto",
          params: config,
        });
      }
    },
    getProductType(id) {
      if (id == 1) {
        return "curso";
      } else if (id == 2) {
        return "diplomado";
      }
    },
    redirectToPay() {
      // if (this.precio === 0) {
      //   const { ok } = await this.buyCourse(this.pao_id);
      //   if (!ok) return;
      //   this.$router.push({ name: "suscription-user" });
      // } else {
      // this.pao_id = 1;
      // this.$router.push("/buy/" + this.pao_id);
      this.$router.push("/" + this.modalInfo.slug + "/pay");
      // }
    },
  },
  props: {
    mode: {
      type: String,
      default: "private",
    },
    // showPrivateNavbar: {
    //   type: Boolean,
    //   default: false,
    // },
    width: Number,
    height: {
      type: String,
      default: "100%",
    },
  },
};
</script>
<style>
.productModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.productModal-close {
  position: absolute;
  height: 50px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  font-size: 3rem;
  color: #ffc107;
  cursor: pointer;
}
.productModal-inner {
  width: 100vw;
  background-color: #fff;
  color: #000;
  position: relative;
  max-width: 50%;
  overflow-y: auto;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  height: 90vh;
}
.productModal-inner h3 {
  margin: 2.5%;
}
.productModal-inner p {
  margin: 2.5%;
}
.productModal-inner img {
  width: 100%;
  aspect-ratio: 16/9;
}
.productModal-extra {
  margin: 2.5%;
}
#productModal-btn {
  border-radius: 15px;
  padding: 10px 50px;
  display: flex;
  float: right;
  margin: 0px 20px 20px 0px;
}
#goToCourse-icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  /*   background: black; */
  border-radius: 4px;
}
</style>
